import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"our-services"} />
		<Helmet>
			<title>
				Menü | Bella Gusto'da Lezzetin Tadını Çıkarın
			</title>
			<meta name={"description"} content={"Indulge in a Symphony of Tastes"} />
			<meta property={"og:title"} content={"Menu | Bella Gusto'da Lezzetin Tadını Çıkarın"} />
			<meta property={"og:description"} content={"Indulge in a Symphony of Tastes"} />
			<meta property={"og:image"} content={"https://nexifygang.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://nexifygang.com/img/pizza-icon-design-free-png.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://nexifygang.com/img/pizza-icon-design-free-png.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://nexifygang.com/img/pizza-icon-design-free-png.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://nexifygang.com/img/pizza-icon-design-free-png.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://nexifygang.com/img/pizza-icon-design-free-png.png"} />
			<meta name={"msapplication-TileImage"} content={"https://nexifygang.com/img/pizza-icon-design-free-png.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="150px 0 150px 0" background="rgba(0, 0, 0, 0) url(https://nexifygang.com/img/4.jpg) 0% 0% /cover repeat scroll padding-box" sm-padding="80px 0 80px 0" quarkly-title="Product-7">
			<Box min-width="100px" min-height="100px" display="flex" justify-content="flex-end">
				<Box
					min-width="100px"
					min-height="100px"
					width="400px"
					background="#ffffff"
					padding="50px 35px 50px 35px"
					display="flex"
					align-items="center"
					flex-direction="column"
					md-padding="35px 35px 35px 35px"
				>
					<Text margin="0px 0px 20px 0px" font="normal 600 32px/1.2 --fontFamily-sans" text-align="center">
						Menü
					</Text>
					<Text margin="0px 0px 35px 0px" text-align="center" font="normal 400 18px/1.5 --fontFamily-sansHelvetica">
						Damak tadınızı kışkırtmak ve daha fazlasını arzulamanızı sağlamak için titizlikle hazırlanmış menümüzle Bella Gusto'nun kalbine dalın. Hem geleneksel hem de modern lezzetlerden oluşan menümüzde herkes için bir şeyler bulabilirsiniz. Mutfak dünyamıza bu bakış, sunacaklarımızın sadece başlangıcıdır.
					</Text>
					<Button background="--color-darkL1" padding="12px 30px 12px 30px" border-radius="25px">
						Bize Ulaşın
					</Button>
				</Box>
			</Box>
		</Section>
		<Section
			background="#ffffff"
			href="/contacts"
			type="link"
			text-decoration-line="initial"
			padding="90px 0 110px 0"
			quarkly-title="Team-8"
		>
			<Text margin="0px 0px 0px 0px" color="--dark" text-align="center" font="600 48px --fontFamily-sans">
				Menu
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				margin="70px 0px 0px 0px"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				lg-padding="0px 0 0px 0"
				lg-margin="50px 0px 0px 0px"
				lg-grid-gap="50px 0"
				sm-grid-template-columns="1fr"
				sm-grid-gap="40px 0"
				flex-direction="row"
				align-items="center"
				grid-gap="60px 40px"
				lg-grid-template-columns="1fr"
				lg-grid-template-rows="4fr"
				color="--darkL2"
			>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					flex-direction="column"
					display="flex"
				>
					<Box
						min-width="100px"
						min-height="100px"
						width="100%"
						sm-width="100%"
					>
						<Image
							src="https://nexifygang.com/img/5.jpg"
							display="block"
							width="auto"
							border-radius="25px"
							lg-max-width="100%"
							lg-width="100%"
							height="280px"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						width="100%"
						padding="0px 0px 0px 35px"
						lg-width="70%"
						lg-padding="0px 0px 0px 25px"
						sm-width="100%"
						sm-padding="0 0 0 0"
					>
						<Text margin="25px 0px 0px 0px" color="--dark" font="600 18px --fontFamily-sans" sm-margin="35px 0px 0px 0px">
							Klasik Pizzalar
						</Text>
						<Text margin="22px 0px 0px 0px" font="300 18px/140% --fontFamily-sansHelvetica" color="--darkL2">
							Taze fesleğen, domates sosu ve mozzarella peyniri ile tatlandırılmış Margherita'mızla İtalya'nın özünü yaşayın veya zamansız bir favori olan Pepperoni'mizin zengin lezzetlerinin tadını çıkarın.
							<br />
							Margherita: San Marzano domates sosu, taze mozzarella, fesleğen yaprakları ve sızma zeytinyağı ile süslenmiş pizzaların kraliçesi. İtalyan bayrağının renklerine saygı duruşunda bulunan zamansız bir klasik.
							<br />
							Pepperoni: Mozzarella peyniri yatağı üzerinde cömert bir biber dilimi tabakası ve çıtır çıtır mükemmel bir şekilde pişirilmiş özel domates sosumuz içeren güçlü ve baharatlı bir favori.
							<br />
							Dört Peynirli: Klasik hamurumuz üzerinde eritilmiş mozzarella, gorgonzola, parmesan ve ricotta'nın kremsi dokularını ve nüanslı lezzetlerini bir araya getiren peynir severlerin rüyası.
							<br />
							Hawaiili: Jambon ve ananasın tuzlu-tatlı kombinasyonunda, mozzarella peyniri ve keskin domates tabanında uyum bulan tartışmalı bir klasik.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					flex-direction="column"
					sm-align-items="center"
					display="flex"
				>
					<Box
						min-width="100px"
						min-height="100px"
						width="100%"
						sm-width="100%"
					>
						<Image
							src="https://nexifygang.com/img/6.jpg"
							display="block"
							width="auto"
							border-radius="25px"
							sm-width="100%"
							lg-max-width="100%"
							lg-width="100%"
							height="280px"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						width="100%"
						padding="0px 0px 0px 35px"
						lg-width="70%"
						sm-width="100%"
						sm-padding="0 0 0 0"
					>
						<Text margin="25px 0px 0px 0px" color="--dark" font="600 18px --fontFamily-sans" sm-margin="35px 0px 0px 0px">
							İmza Kreasyonları
						</Text>
						<Text margin="22px 0px 0px 0px" font="300 18px/140% --fontFamily-sansHelvetica" color="--darkL2">
							Kremalı trüf soslu ve yabani mantarlı Trüf Mantarlı veya ateşi yükseltmeye cesaret edenler için Baharatlı Diavolo gibi özel pizzalarımızla bir lezzet macerasına çıkın.
							<br />
							Trüf Mantarlı: Zengin yabani mantar karışımını kremsi trüf mantarı ile aşılanmış beyaz sos üzerinde sergileyen, mozzarella peyniri ve topraksı, aromatik bir deneyim için bir tutam taze maydanoz ile tamamlanan gurme bir zevk.
							<br />
							Baharatlı Diavolo: Sıcak isteyenler için bu pizza, mozzarella peynirinin serinliğiyle dengelenmiş baharatlı İtalyan sosisi, jalapeños, kırmızı pul biber ve baharatlı domates sosu ile ateşi getiriyor.
							<br />
							Barbekü Tavuk: Dumanlı barbekü sosuyla marine edilmiş yumuşak tavuk göğsü parçaları, kırmızı soğan, kişniş ve mozzarella ve cheddar peynirlerinin karışımıyla pizzaya modern bir dokunuş.
							<br />
							Pesto Garden: Taze ve lezzetli bir ısırık için mevsim sebzeleri, keçi peyniri ve bir tutam çam fıstığı ile doldurulmuş ev yapımı pesto sos tabanı ile yemyeşil ve canlı bir seçenek.


						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					flex-direction="column"
					sm-align-items="center"
					display="flex"
				>
					<Box
						min-width="100px"
						min-height="100px"
						width="100%"
						sm-width="100%"
					>
						<Image
							src="https://nexifygang.com/img/7.jpg"
							display="block"
							width="auto"
							border-radius="25px"
							sm-width="100%"
							lg-max-width="100%"
							lg-width="100%"
							height="280px"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						width="100%"
						padding="0px 0px 0px 35px"
						lg-width="70%"
						sm-width="100%"
						sm-padding="0 0 0 0"
					>
						<Text margin="25px 0px 0px 0px" color="--dark" font="600 18px --fontFamily-sans" sm-margin="35px 0px 0px 0px">
							Özel Servisler
						</Text>
						<Text margin="22px 0px 0px 0px" font="300 18px/140% --fontFamily-sansHelvetica" color="--darkL2">
							Özel Etkinlikler: Bella Gusto, özel günleriniz için eşsiz bir mekan sunuyor. Doğum günü partilerinden kurumsal toplantılara kadar, etkinliğinizi unutulmaz kılmamıza izin verin.
							<br />
							Catering Hizmetleri: Her büyüklükteki toplantı için mükemmel olan catering hizmetlerimizle Bella Gusto'nun lezzetini kapınıza getirin.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					flex-direction="column"
					sm-align-items="center"
					display="flex"
				>
					<Box
						min-width="100px"
						min-height="100px"
						width="100%"
						sm-width="100%"
					>
						<Image
							src="https://nexifygang.com/img/8.jpg"
							display="block"
							width="auto"
							border-radius="25px"
							sm-width="100%"
							lg-max-width="100%"
							lg-width="100%"
							height="280px"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						width="100%"
						padding="0px 0px 0px 35px"
						lg-width="70%"
						lg-padding="0px 0px 0px 25px"
						sm-width="100%"
						sm-padding="0 0 0 0"
					>
						<Text margin="25px 0px 0px 0px" color="--dark" font="600 18px --fontFamily-sans" sm-margin="35px 0px 0px 0px">
							Bir Menüden Daha Fazlası
						</Text>
						<Text margin="22px 0px 0px 0px" font="300 18px/140% --fontFamily-sansHelvetica" color="--darkL2">
							Menümüz, pizzaya olan sevgimizin ve kaliteye olan bağlılığımızın bir kanıtıdır, ancak Bella Gusto'da yemek yemeyi unutulmaz bir deneyim haline getiren şeyin sadece bir parçasıdır. Herhangi bir diyet tercihiniz veya kısıtlamanız varsa, bize ulaşmaktan çekinmeyin. Ekibimiz, ihtiyaçlarınızı karşılamaya ve keyifli bir yemek deneyimi yaşamanızı sağlamaya kendini adamıştır.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});